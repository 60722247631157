.cardSize {
    width: 50%;
    height: 100%;
    margin-top: .5rem;
    margin-bottom: 1rem;
}
.cardRow {
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}
.modalText{
    color: black;
}