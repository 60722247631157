.titleIAMGAMEText {
    color: #e63946; /* Adjust the color to fit your theme */
    font-weight: bold;
}

.display-4 {
    font-size: 2.5rem; /* Adjust size as needed */
    font-weight: 700; /* Makes it bolder */
}

.lead {
    font-size: 1.25rem; /* Larger text for the first paragraph */
    font-weight: 300; /* Lighter, for contrast */
}

.aboutText p {
    margin-bottom: 1rem; /* Adds more space between paragraphs */
}