.table {
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .table th, .table td {
    text-align: left;
    padding: 8px;
  }
  
  .table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table tr:hover {
    background-color: #ddd;
    cursor: pointer;
  }
  
  .table th {
    background-color: #A9A9A9; /* Dark Gray */
    color: white;
  }
  
  .gameNameCol {
    font-weight: bold;
    width: 75%;
  }

  .table tr div {
    display: flex;
    align-items: center;
  }

  .table tr div {
    display: flex;
    align-items: center;
  }

  .table tr div span {
    margin-right: 30px;
  }

  .table tr div input {
    flex-grow: 1;
  }
  
  .addButton {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  
  .addButton:hover {
    background-color: #45a049;
  }