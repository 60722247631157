.checkboxGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Creates a responsive multi-column layout */
    gap: 10px; /* Space between checkboxes */
  }

  .stickyFooter {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 10px;
    text-align: right;
}

.modalContainer {
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Center items horizontally */
  height: 100vh; /* Full viewport height */
}