.shareModal {
    color:black;
    border: none;
}

.shareModalBody {
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
    justify-content: center;
    align-items: center;
}

.shareOptions {
    justify-items: center;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    border: none;
    box-shadow: none;
    outline: none;
    font-size: 1.5rem;
    max-width: 3rem;
}

