.cardImage {
  object-fit: cover;
  padding: 0%;
  max-height: fit-content;
  width: 100%;
  height: auto;
  border-radius: 0%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.innerRow {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.cardText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 40%;
  -webkit-box-orient: vertical;
}

.cardTitle {
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.cardFormat {
  background-color: #121212;
  border-color: white;
  color: white;
  border-radius: 5px;
  overflow: clip;
  flex-direction: row;
  height: 100%;
  padding: 10px;
}

.cardBody {
  background-color: transparent;
  padding: 0;
}

.cardWrapper {
  padding-top: 20px;
}

.cardFooter {
  padding: 0;
}

.cardButton {
  height:auto;
}