.infoCard{
    border-color: #c2c2c2;
    max-width: 100%;
    background-color: #121212;
    color: white;
}

.infoCardList{
    border-radius: 0px;
    border: none;
}

.infoCardListItem{
    border: none;
    padding: 1.25rem;
    align-items: center;
    background-color: #121212;
    color: white;
}

.groupPic{
    object-fit: cover;
    max-height: 20rem;
    width: 100%;
    border-radius: 0;
}

.aboutTabCards{
    justify-content: space-between;
}

.cardTitle{
    align-items: center;
    padding-bottom: 0.5rem;
}