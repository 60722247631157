.playModal {
    color: black;
    border: none;
}

.playModalHead {
    color: black;
    border: none;
    display: flex; 
    justify-content: center;
    align-items: center;
}

.playModalBody {
    color: black;
    border: none;
}

.playModalContent {
    justify-content: space-evenly;
    align-items: center;
}

.playModalFoot {
    border: none;
}