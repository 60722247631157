ol {
    counter-reset: item;
}
li {
    display: block;
    padding-bottom: 5px;
}
.lineItems li:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
  }