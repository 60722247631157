.serviceImg {
    width:200px;
    height: 200px;
    float: none;
}

.headerList li {
    list-style-type: none;
    justify-content: center;
    text-align: center;
}

.titleText {
    color:#7f8bfd;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
}

.welcomeTitle {
    font-size:56px;
}

.headerContent {
    justify-content: center;
    text-align: center;
}

.homePage {
    background: url("../img/alphabg.jpg");
    background-size: cover;
}

.homeContainer {
   background-color: rgb(0, 0 ,0, 0.8);
}