.createButton{
    background-color: transparent;
    border-color: #e65017;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    width: fit-content;
    max-width: 60%;
}

.createButton:hover{
    background-color: #ce4915;
    border-color: transparent;
}

.createButton:active{
    background-color: #ce4915;
    border-color: transparent;
}

.createModal{
    color: black;
}