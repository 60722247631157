.searchIcon {
  background: gray;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  padding: 4px 10px;
  border: 5px;
}

.form {
  padding: 1em;
}

