.groupSearchHeader{
    justify-content: space-between;
    align-items: last baseline;
    flex-wrap: nowrap;
}

.allContent{
    column-gap: 1rem;
}

.groupList{
    flex-wrap: wrap;    
}

.sortingSelect{
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
}

.formLabel{
    white-space: nowrap;
    justify-self: right;
    color: white;
}

.createGroup{
    justify-self: flex-end;
    flex-shrink: 2;
}
