@import url("https://fonts.googleapis.com/css2?family=Oswald&family=Ubuntu:wght@300;400&display=swap");

.profileImage {
  width: 100px;
  border-radius: 50px;
  float: left;
  margin-right: 10px;
}

body {
  font-family: "Ubuntu", sans-serif;
  margin-bottom: 1px;
  font-weight: lighter;
}

.save_button {
  color: #ff5315;
  background-color: black;
  border-color: #ff5315;
  margin-top: 1rem;
  border-radius: 50px;
}

.test {
  color: #ff5315;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.headers {
  font-size: 1rem;
  color: #ff5315;
}

.bio_body {
  font-family: "Ubuntu", sans-serif;
}

.account_section_headings {
  font-size: 1rem;
  font-family: "Oswald";
  color: #ff5315;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 15px;
  padding-top: 5px;
}
.profileInfo {
  font-family: "Ubuntu", sans-serif;
  text-transform: lowercase;
  font-weight: 50;
  margin-bottom: 15px;
  padding-bottom: 5px;
  color: white;
}
.checkbox {
  font-weight: 50;
  font-size: larger;
  font-family: "Oswald";
  margin-left: 1rem;
}
.container {
  display: flex;
  justify-content: space-around;
}
.options {
  padding-left: 1rem;
  margin: 1px solid #dddddd;
  margin-bottom: 1px;
}
input:checked ~ .options:after {
  display: block;
  border-color: 1px solid white;
}
.options input {
  color: yellow;
  background-color: yellow;
}
.options:after {
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.tabs {
  display: flex;
  flex-wrap: wrap;
}

.tabs__label {
  padding: 10px 16px;
  cursor: pointer;
}

.tabs__radio {
  display: none;
}

.tabs__content {
  order: 1;
  width: 100%;
  line-height: 1.5;
  font-size: 0.9em;
  display: none;
}

.tabs__radio:checked + .tabs__label {
  font-weight: bold;
  color: #ff5315;
  border-bottom: 2px solid #ff5315;
}

.tabs__radio:checked + .tabs__label + .tabs__content {
  display: flex;
}

.container-tabs .nav-tabs .nav-link {
  background-color: black;
  color: #dddddd;
}

.nav-tabs .nav-link.active {
  border: 0;
  background-color: black;
  font-weight: bold;
  color: #ff5315;
  border-top: 2px solid #ff5315;
}

/*for MyGamesList*/
.cardSize {
  width: 50%;
  height: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
