.footer {
  background-color: #0a0a0a;
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  top: 1rem;
  min-height: 200px;
}
.listTitle {
  background-color: #0a0a0a;
  border: none;
  color: white;
}

.listContent {
  text-align: center;
  background-color: #0a0a0a;
  border: none;
  color: white;
}
