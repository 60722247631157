
.ThePage {
    background-color: black;
    width: 100%;
    height: 100%;
    position: fixed;
}

body {
    color: white;
}

.direction {
    width: 10%;
    background: #ff5315;
    border-color: #ff5315;
    outline: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 12px;
    color: #625d5d;
    font-weight: 600;
}
