/* To use on any page use the following import statement: */
/* import '../global.css';  (Adjust based on file path relationship) */

/* This css file is to be used for standardized styling of the IAMGAME website */
/* This includes styling for buttons, headers, form control (input boxes in forms),*/
/* background color, and text color.  */
@import url("https://fonts.googleapis.com/css2?family=Oswald&family=Ubuntu:wght@300;400&display=swap");

/**** BASIC ELEMENTS ****/
body {
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
  color: white;
  background-color: #121212;
}

a {
  color: white;
}

a:hover {
  color: #7f8bfd;
}
.navbar-brand:hover {
  color: #ff4700;
}

/* For all headers */
.header {
  text-align: center;
  font-family: "Oswald", sans-serif;
  font-size: 3.5rem;
  font-weight: 500 px;
  color: white;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
}

main {
  padding: 10px;
}

/**** Table formatting ****/
th {
  color: white;
}

img {
  max-width: 100%;
}

/**** FORMS ****/
.form-text {
  color: #625d5d;
}
label.form-label {
  text-align: center;
  font-family: oswald-medium, oswald, sans-serif;
  font-size: 1.125rem;
  font-weight: 500 px;
}
.form-label {
  text-align: center;
  font-family: oswald-medium, oswald, sans-serif;
  font-size: 1.125rem;
  font-weight: 50 px;
  color: grey;
}

/* For input boxes of forms */
.form-control::placeholder {
  position: relative;
  width: 100%;
  height: 2.5rem;
  margin: 10px 0;
  color: grey;
}

/**** BUTTONS ****/

/* For Standard buttons */
/* If you have a special button, copy and paste. Adjust size*/
.btn {
  width: 100%;
  height: 75%;
  background: black;
  border-color: #ff4700;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 95%, min(40px);
  color: #ff4700;
  font-weight: 550;
}

.btn:hover {
  background-color: #ff4700;
  border-color: #ff4700;
  outline: none;
  color: #121212;
}

.btn:focus {
  background-color: #7f8bfd;
  border-color: white;
  color: white;
}

.btn:active {
  background-color: #7f8bfd;
  border-color: white;
  color: white;
}

/* For Large buttons */
.btn-group-lg,
.btn-lg {
  width: 100%;
  height: 2.813rem;
  background: #ff4700;
  border: none;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 1rem;
  color: #121212;
  font-weight: 550;
}

.btn-group-lg,
.btn-lg:hover {
  background-color: #121212;
  border: 1px solid #ff4700; /* Ensure border is applied */
  color: #ff4700;
}

.nav-tabs .nav-link {
  background-color: transparent;
  color: #dddddd;
}

.nav-link {
  font-weight: 500;
}

.nav-tabs .nav-link.active {
  border: 0;
  background-color: transparent;
  color: #ff4700;
  border-bottom: 2px solid #ff4700;
}

/**** TABS ****/

/**** MODALS ****/
.modal {
  color: black;
  font-size: 12px;
}

.modal-content {
  background-color: #dfe1e3; /* Light grey background */
  max-height: 90vh; /* Limits the modal height to 90% of the viewport height */
  overflow-y: auto;
}

.modal-dialog {
  max-width: 40%;
  margin: 30px auto;
}

@media screen and (max-width: 1262px) {
  .modal-dialog {
    max-width: 50%;
  }
}

@media screen and (max-width: 1074px) {
  .modal-dialog {
    max-width: 60%;
  }
}

@media screen and (max-width: 900px) {
  .modal-dialog {
    max-width: 90%;
  }
}


/* Amplify Autheniitcator overrides */
[data-amplify-container] * {
  font-family: 'Oswald', sans-serif;
}

[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--amplify-colors-purple-60);
  --amplify-components-button-primary-background-color: black;
  --amplify-components-button-primary-color: #ff4700;
  --amplify-components-button-primary-border-color: #ff4700;
  --amplify-components-button-primary-border-width: 1px;
  --amplify-components-button-primary-border-radius: 4px;
  --amplify-components-button-primary-padding: 10px 15px;
  --amplify-components-button-primary-font-size: 16px;
}

.modal [data-amplify-container] {
  display: flex;
  flex-direction: column; /* Example direction, adjust as needed */
  justify-content: center; /* Example alignment, adjust as needed */
  width: 100% !important;
}