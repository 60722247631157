@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

body {
  font-family: "Oswald";
}
.logo {
  width: 100px;
}
.navbar-brand:hover {
  color: #505050;
}
.globalNavMenu {
  background-color: #f03207;
  font-size: 22px;
  padding-right: 20%;
  min-height: 100px;
  align-items: center;
}

.globalNav {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
}

sup {
  top: -1em;
  font-size: 0.5em;
}

.version {
  transform: rotate(57deg);
  font-size: x-large;
}

@media screen and (max-width: 578px) {
  .version {
    font-size: large;
  }
}
