.editButton {
  align-self: right;
  width: 100%;
  height: 75%;
  background: black;
  border-color: #ff4700;
  outline: none;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 13px;
  color: #ff4700;
  font-weight: 550;
}

.editButton:hover {
  background-color: #ce4915;
  border-color: transparent;
}

.editButton:active {
  background-color: #ce4915;
  border-color: transparent;
}

.editModal {
  color: black;
  font-size: 12px;
}

.modal-content {
  background-color: #ce4915;
}
