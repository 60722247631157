.cardRow{
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
}

.cardCol {
    margin-top: .5rem;
    margin-bottom: 1rem;
}

.card {
    max-height: 200px;
}